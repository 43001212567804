


















































































import {
    Component,
    Vue
} from 'vue-property-decorator';
import {
    Core
} from '@/store/core'
import {
    Auth
} from '@/store/auth'
import {
    User
} from '@/store/user'
import _ from 'lodash'
import {
    Web
} from '@/store/web'
import moment from 'moment'
@Component({
    components: {

    },
})

export default class Result extends Vue {
    user: any = null;
    response: boolean = false
    myAgency: any = 0
    myAgencyData: any = null
    years: any = []
    chooseYear: any = '2565'
    IITYEAR: any = null;
    EITYEAR: any = null;
    OITYEAR: any = null;
    countAllOIT: any = 0;
    countOIT: any = 0;
    countIIT: any = 0;
    countEIT: any = 0;
    listIIT: any = [];
    listEIT: any = [];
    search: any = ''
    headers: any = [{
            text: 'ที่',
            value: 'index'
        },
        {
            text: 'บัญชีผู้ใช้งาน',
            value: 'fullname'
        }, 
    ] 
    async getCurrentYear(){
        try {
            this.chooseYear = (Number(moment().format('YYYY')) + 543).toString() 
        } catch (error) {
            this.chooseYear = '2566'
        }
    }
    async created() {
      try {
          await Web.switchLoad(true)
        await this.getCurrentYear();
        this.user = await User.getUser();
        console.log('panel agen number : ',this.user.ext_link.agency);
        this.myAgency = this.user.ext_link.agency
        this.years = await Core.getHttp(`/api/ita/v1/year/`)
        this.myAgencyData = await Core.getHttp(`/api/ita/v1/agency/${this.myAgency}/`)
        await this.getIITYear()
        await this.getEITYear()
        await this.getOITYear()
        await this.getOIT();
        await this.getOITResult()
        await this.getIITRes();
        await this.getEITRes();
        await Web.switchLoad(false)
       this.response = true;
      } catch (error) {
           await Web.switchLoad(false)
           alert('ระบบยังไม่เปิดให้บริการ กรุณาติดต่อผู้ดูแลระบบ')
      }
    }

    iitResponse: boolean = false;
    async getIITYear() {
        try {
            let yearIIT = await Core.getHttp(`/api/iit/v2/year/?year=${this.chooseYear}`)
        if (yearIIT.length > 0) {
            this.IITYEAR = yearIIT[0]
            this.iitResponse = true
        } else {
            this.IITYEAR = null
            this.iitResponse = false
        }
        } catch (error) {
            
        }
    }

    eitResponse: boolean = false;
    async getEITYear() {
        try {
            let yearEIT = await Core.getHttp(`/api/eit/v2/year/?year=${this.chooseYear}`)

        if (yearEIT.length > 0) {
            this.EITYEAR = yearEIT[0]
            this.eitResponse = true;
        } else {
            this.EITYEAR = null
            this.eitResponse = false
        }
        } catch (error) {
            
        }
    }

    async getIITRes() {
        try {
            let data = await Core.getHttp(`/api/iit/v2/ansewer/user/?agency=${this.myAgency}&year=${this.IITYEAR.id}`)
        //console.log(data);
        this.listIIT = data;
        this.listIIT = _.map(this.listIIT, (item, index) => {
            item.index = index + 1
            return item
        })
        this.countIIT = data.length
        } catch (error) {
            
        }
    }

    async getEITRes() {
        try {
            let data = await Core.getHttp(`/api/eit/v2/answersuggestioneit/?agency=${this.myAgency}&year=${this.EITYEAR.id}`)
        this.listEIT = data;
        //console.log(data);
        this.countEIT = data.length 
        } catch (error) {
            
        }
    }

    async loadData() {
        await this.getIITYear();
        await this.getEITYear();
        await this.getIITRes();
        await this.getEITRes();

        await this.getOITYear();
        await this.getOITResult();
    }

    private async getOITYear() {
       try {
        let years = await Core.getHttp(`/api/ita/v1/year/`)
        console.log(years)
        let year: any = await _.find(years, {
            "year": this.chooseYear
        })
        this.OITYEAR = year
        console.log('getOITYear',year)
       } catch (error) {
            console.log(error);
       }
    }

    private async getOIT() {
       try {
        //console.log('getOIT', this.OITYEAR)
        let oit = await Core.getHttp(`/api/ita/v2/rate/${this.OITYEAR.id}/`)
        console.log('getOIT',oit.length);
        this.countAllOIT = oit.length;
       } catch (error) {
        
       }
    }
    private async getOITResult() {
        try {
            let raw = await Core.getHttp(`/api/ita/v1/rateresult/?agency=${this.myAgency}&rate__year=${this.OITYEAR.id}`);
          
        let result = await _(raw)
            .groupBy('rate')
            .map(function (items, data) {
                return {
                    data: data,
                };
            }).value();
            console.log('getOIT',this.OITYEAR.id,result.length);
        this.countOIT = result.length;
        } catch (error) {
            
        }

    }
}
